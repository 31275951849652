import { Link as GatsbyLink, graphql } from 'gatsby';
import { Heading, Box, Link } from '@chakra-ui/react'

import { formatPostDate } from '../utils/helpers';

import Footer from '../components/Footer';
import Layout from '../components/Layout';
import React from 'react';
import SEO from '../components/SEO';
import get from 'lodash/get';
import { weeklyReadingUrl } from '../utils/urls';
import { genWeekPostTitle } from '../utils/seo';

const isProd = process.env.NODE_ENV === 'production';

const sortByDate = posts =>
    posts.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
    });

const ArticleList = ({ posts, locale }) => {
    return (
        <>
            {sortByDate(posts).map(({ url, title, publish, date }) => {
                return (
                    <article key={url}>
                        <Box as="header" mb={3}>
                            <Link color="blue.600" as={GatsbyLink} to={url} rel="bookmark" display="block">
                                {publish ? title : `[draft] ${title}`}
                            </Link>
                            <small>{formatPostDate(date, locale)}</small>
                        </Box>
                        {/* <p
              dangerouslySetInnerHTML={{ __html: spoiler }}
            /> */}
                    </article>
                );
            })}
        </>
    );
};

class BlogIndexTemplate extends React.Component {
    render() {
        const siteTitle = get(this, 'props.data.site.siteMetadata.title');
        const langKey = this.props.pageContext.langKey;

        const filteredPosts = get(this, 'props.data.allMarkdownRemark.edges')
            .map(post => {
                if (isProd && !post.node.frontmatter.publish) {
                    return null;
                }
                return {
                    url: post.node.frontmatter.url,
                    title: post.node.frontmatter.title,
                    publish: post.node.frontmatter.publish,
                    date: post.node.frontmatter.date,
                };
            })
            .filter(i => i);

        const weeklyPosts = get(this, 'props.data.releaseWeeks.edges').map(
            ({ node }) => {
                const date = node.data.Date;
                return {
                    url: weeklyReadingUrl(date),
                    title: genWeekPostTitle(date),
                    publish: true,
                    date: date,
                };
            }
        );

        return (
            <Layout location={this.props.location} title={siteTitle} locale={langKey}>
                <SEO />
                {/* <aside>
          <Bio />
        </aside> */}
                <main style={{ minHeight: '60vh' }}>
                    <Heading as="h2" size="large" my={3}>Posts</Heading>
                    <ArticleList posts={filteredPosts} locale={langKey} />
                    <Heading as="h2" size="large" my={3}>Weekly read</Heading>
                    <ArticleList posts={weeklyPosts} locale={langKey} />
                </main>
                <Footer locale={langKey} />
            </Layout>
        );
    }
}

export default BlogIndexTemplate;

export const pageQuery = graphql`
  query($langKey: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      filter: { fields: { langKey: { eq: $langKey } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            langKey
          }
          timeToRead
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            spoiler
            publish
            url
          }
        }
      }
    }
    releaseWeeks: allAirtable(
      filter: { queryName: { eq: "Release" }, data: { Release: { eq: true } } }
    ) {
      edges {
        node {
          id
          data {
            Date
          }
        }
      }
    }
  }
`;
