const { dateToYearWeekNumber, getYear } = require('./date');

const genWeekPostTitle = yyyymmdd => {
    const year = getYear(yyyymmdd);
    const weekNumber = dateToYearWeekNumber(yyyymmdd);
    return `${year} 第 ${weekNumber} 週 閱讀週記`;
};

exports.genWeekPost = (yyyymmdd, post) => {
    const postTitles = post.map(({ node }) => node.data.Title).join(';');
    const title = genWeekPostTitle(yyyymmdd);

    return {
        title,
        description: `${title}文章閱讀心得: ${postTitles}`,
    };
};

exports.genWeekPostTitle = genWeekPostTitle;
